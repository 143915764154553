/* eslint-disable */
import Vue from "vue";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
  HubConnectionState,
} from "@microsoft/signalr";
import store from "@/store";

import { getUserData } from "@/auth/utils";

import Helper from "@/libs/Helper";

// export default ({ app, store, $auth }, inject) => {
const consultHub = new HubConnectionBuilder()
  .withUrl(`${Helper.baseUrl}hub/privateConsultationHub`, {
    accessTokenFactory: function () {
      return getUserData().data.token;
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
  .withAutomaticReconnect()
  .build();

consultHub.onclose(() => {
  store.commit("consultSocketState/setConnectionState", false);
});
// with reconnect capability (async/await, not IE11 compatible)
function start() {
  consultHub
    .start()
    .then(() => {
      store.commit("consultSocketState/setConnectionState", true);
    })
    .catch((err) => console.error(err));
}
start();
// };
export default consultHub;
