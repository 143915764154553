import Vue from "vue";
// import Vuex from "vuex";
import store from '@/store'
import Helper from "@/libs/Helper";
// import inject from 'vue'
// Vue.use(Vuex)
import VueCompositionAPI, {inject} from '@vue/composition-api'
// import { inject } from 'vue'
import {HubConnectionBuilder, LogLevel, HttpTransportType} from "@aspnet/signalr";
// export default (inject) => {

// Define Hub
const hub = new HubConnectionBuilder()
    .withUrl(`${Helper.baseUrl}chatHub`, {
        // transport:HttpTransportType.WebSockets,
        // skipNegotiation:true,
        accessTokenFactory: function () {
            // console.log("Getting token for hub");
        },
    })
    .configureLogging(LogLevel.Information)
    .build();

const activityHub = new HubConnectionBuilder()
    .withUrl(`${Helper.baseUrl}hub/activity`, {
        // transport:HttpTransportType.WebSockets,
        // skipNegotiation:true,
        accessTokenFactory: function () {
            // console.log("Getting token for activityHub");
        },
    })
    .configureLogging(LogLevel.Information)
    .build();

// Inject
inject("hub", hub)

inject("activityHub", activityHub)

// Watch Methods
hub.on("SendMessage", (res) => {
    store.commit("sendMessage", res);
    // console.log("Message sended by", res);
});
hub.on("ReadMessage", (res) => {
    store.commit("sendMessage", res);
    // console.log("Message readed by", res);
});
hub.on("DeliverMessage", (res) => {
    // store.commit("sendMessage", res);
    // console.log(`Message Delivered to ${res}`);
});
activityHub.on("UpdateCount", (res) => {
    store.commit("SetOnlineUsers", res);
    // console.log(`UpdateCount : ${res}`);
});

// Hub starts
hub.start().catch(function (err) {
    return console.error(err);
});
activityHub.start().catch(function (err) {
    return console.error(err);
});

// Hub start Functions
// with reconnect capability (async/await, not IE11 compatible)
async function start() {
    try {
        // console.log("Attempting reconnect");
        await hub.start();
        await activityHub.start();
    } catch (err) {
        // console.log(err);
        setTimeout(() => start(), 5000);
    }
}

// Hub Close Functions
hub.onclose(async () => {
    await start();
});
activityHub.onclose(async () => {
    await start();
});


// sample sending of message
/*
hub
  .invoke('SendMessageToOthers', {
    action: action,
    data: Object.assign(
      { user: get(store, 'getters.loggedUser.name') },
      message
    )
  })
  .catch(err => console.error(err.toString()))
*/
// }
