export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chats',
    name: 'apps-chats',
    component: () => import('@/views/apps/chat/Chats.vue'),
    title:'چت ها',
    meta: {
      resource: 'Support',
      action: 'Support',
      pageTitle: 'چت ها',
      // breadcrumb: [
      //   {
      //     text: 'لیست',
      //     active: true
      //   },
      // ],
    },
  },
  {
    path: '/apps/chats/:id',
    name: 'apps-chats-id',
    resource: 'Support',
    action: 'Support',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      // contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  {
    path: '/apps/buy/:id',
    name: 'apps-buy-id',
    component: () => import('@/views/apps/buy/Buy.vue'),
    meta: {
      pageTitle: 'ثبت سفارش کاربر',
    }
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/students',
    name: 'apps-e-commerce-students',
    component: () => import('@/views/apps/e-commerce/students/StudentsList.vue'),
    // meta: {
    //   pageTitle: 'دانشجویان',
    // },
  },
  {
    path: '/apps/e-commerce/studentInfoes/:id',
    name: 'apps-e-commerce-studentInfoes',
    component: () => import('@/views/apps/e-commerce/students/StudentInfoes.vue'),
  },
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      // pageTitle: 'محصولات',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'لیست',
      //     active: true
      //   },
      // ],
    },
  },
  {
    path: '/apps/e-commerce/add',
    name: 'apps-e-commerce-add',
    component: () => import('@/views/apps/e-commerce/e-commerce-add/ECommerceAdd.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      // pageTitle: 'محصولات',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'افزودن محصول',
      //     active: true
      //   },
      // ],
    },
  },
  {
    path: '/apps/e-commerce/add-course',
    name: 'apps-e-commerce-add-course',
    component: () => import('@/views/apps/e-commerce/e-commerce-add-course/ECommerceAddCourse'),
    meta: {
      contentClass: 'ecommerce-application',
      // pageTitle: 'محصولات',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'افزودن دوره',
      //     active: true
      //   },
      // ],
    },
  },
  {
    path: '/apps/e-commerce/add-product-media/:id',
    name: 'apps-e-commerce-add-product-media',
    component: () => import('@/views/apps/e-commerce/e-commerce-add-product-media/ECommerceAddProductMedia.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      // pageTitle: 'محصولات',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'افزودن مدیا',
      //     active: true
      //   },
      // ],
    },
  },
  //   WARNING MAY CREATE CONFLICT
  {
    path: '/apps/e-commerce/packages/list/:id',
    name: 'apps-e-commerce-packages-list-id',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/PackagesList.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      // pageTitle: 'محصولات',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'افزودن مدیا',
      //     active: true
      //   },
      // ],
    },
  },
  {
    path: '/apps/e-commerce/packages/create/:id',
    name: 'apps-e-commerce-packages-create-item-id',
    component: () => import('@/views/apps/e-commerce/packages/CreatePackageItem.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      // pageTitle: 'محصولات',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'افزودن مدیا',
      //     active: true
      //   },
      // ],
    },
  },


  //   WARNING MAY CREATE CONFLICT


  // {
  //   path: '/apps/e-commerce/wishlist',
  //   name: 'apps-e-commerce-wishlist',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
  //   meta: {
  //     pageTitle: 'Wishlist',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Wishlist',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/courses',
    name: 'apps-e-commerce-courses',
    component: () => import('@/views/apps/e-commerce/courses/CoursesList.vue'),
    meta: {
      // pageTitle: 'دوره ها',
      contentClass: 'ecommerce-application',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'محصولات',
      //   },
      //   {
      //     text: 'لیست دوره ها',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/apps/e-commerce/comments',
    name: 'apps-e-commerce-comments',
    component: () => import('@/views/apps/e-commerce/comments/ProductComments'),
    meta: {
      // pageTitle: 'نظرات',
      contentClass: 'ecommerce-application',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'محصولات',
      //   },
      //   {
      //     text: 'لیست نظرات',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/apps/e-commerce/comments/:id',
    name: 'apps-e-commerce-comments-edit',
    component: () => import('@/views/apps/e-commerce/comments/ProductCommentEdit.vue'),
    meta: {
      // pageTitle: 'ویرایش',
      contentClass: 'ecommerce-application',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'محصولات',
      //   },
      //   {
      //     text: 'کامنت ها',
      //   },
      //   {
      //     text: 'ویرایش',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/apps/e-commerce/courses/edit/:id',
    name: 'apps-e-commerce-courses-edit',
    component: () => import('@/views/apps/e-commerce/courses/CoursesEdit.vue'),
    meta: {
      // pageTitle: 'دوره ها',
      resource: 'ProductManager',
      action: 'ProductManager',
      contentClass: 'ecommerce-application',
      // breadcrumb: [
      //   {
      //     text: 'محصولات',
      //   },
      //   {
      //     text: 'لیست دوره ها',
      //   },
      //   {
      //     text: 'ویرایش',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/apps/e-commerce/courses/stream',
    name: 'apps-e-commerce-courses-stream',
    component: () => import('@/views/apps/e-commerce/courses/CourseStream2.vue'),
    // meta: {
    //   pageTitle: 'دوره ها',
    //   contentClass: 'ecommerce-application',
    //   breadcrumb: [
    //     {
    //       text: 'محصولات',
    //     },
    //     {
    //       text: 'لیست دوره ها',
    //     },
    //     {
    //       text: 'ویرایش',
    //       active: true,
    //     },
    //   ],
    // },
  },
  {
    path: '/apps/e-commerce/categories',
    name: 'apps-e-commerce-categories',
    component: () => import('@/views/apps/e-commerce/categories/categories-list.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/e-commerce/courses/categories',
    name: 'apps-e-commerce-courses-categories',
    component: () => import('@/views/apps/e-commerce/courses/CourseCategories.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/e-commerce/categories/edit/:id',
    name: 'apps-e-commerce-categories-edit',
    component: () => import('@/views/apps/e-commerce/categories/categories-edit.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/e-commerce/:id',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      // pageTitle: 'اطلاعات محصول',
      contentClass: 'ecommerce-application',
      resource: 'ProductManager',
      action: 'ProductManager',
      // breadcrumb: [
      //   {
      //     text: 'محصولات',
      //   },
      //   {
      //     text: 'لیست',
      //   },
      //   {
      //     text: 'ویرایش',
      //     active: true,
      //   },
      // ],
    },
  },
  // EducationalPost
  {
    path: '/apps/educational-post-list',
    name: 'apps-educational-post-list',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ListEducationalPost.vue'),
    meta: {

      contentClass: 'ecommerce-application',
      resource: 'ProductManager',
      action: 'ProductManager',
    },
  },
  {
    path: '/apps/educational-post-add',
    name: 'apps-educational-post-add',
    component: () => import('@/views/apps/e-commerce/educational-post/CreateEducationalPost.vue'),
  },
  {
    path: '/apps/educational-post/edit/:id',
    name: 'apps-educational-post-edit',
    component: () => import('@/views/apps/e-commerce/educational-post/EditEducationalPost.vue'),
  },
  // Live
  {
    path: '/apps/live-list',
    name: 'apps-live-list',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/LiveList.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'ProductManager',
      action: 'ProductManager',
    },
  },
  {
    path: '/apps/live-add',
    name: 'apps-live-add',
    component: () => import('@/views/apps/e-commerce/live/CreateLive.vue'),
  },
  {
    path: '/apps/live/edit/:id',
    name: 'apps-live-edit',
    component: () => import('@/views/apps/e-commerce/live/EditLive.vue'),
  },
  //  Coupons
  {
    path: '/apps/coupons/list',
    name: 'apps-coupons-list',
    component: () => import('@/views/apps/coupon/CouponList.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
    }
  },
  {
    path: '/apps/coupon/add',
    name: 'apps-coupon-add',
    component: () => import('@/views/apps/coupon/CouponAdd.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
    }
  },
  {
    path: '/apps/coupon/edit/:id',
    name: 'apps-coupon-edit',
    component: () => import('@/views/apps/coupon/CouponEdit.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
    }
  },
  {
    path: '/apps/coupon/used/:id',
    name: 'apps-coupon-used',
    component: () => import('@/views/apps/coupon/CouponUsed.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
    }
  },

  //  Seminar
  {
    path: '/apps/seminar/list',
    name: 'apps-seminar-list',
    component: () => import('@/views/apps/seminar/SeminarList.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
    }
  },
  {
    path: '/apps/seminar/editPage',
    name: 'apps-present-banner-edit',
    component: () => import('@/views/apps/edit-pages/PresentSeminarRequest.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
    }
  },
  {
    path: '/apps/seminar/add',
    name: 'apps-seminar-add',
    component: () => import('@/views/apps/seminar/SeminarAdd.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
    }
  },
  {
    path: '/apps/seminar/trailer',
    name: 'apps-seminar-trailer',
    component: () => import('@/views/apps/seminar/SeminarTailer.vue'),
    meta: {
      resource: 'ProductManager',
      action: 'ProductManager',
    }
  },
  {
    path: '/apps/seminar/members/:id',
    name: 'apps-seminar-members',
    component: () => import('@/views/apps/seminar/SeminarMembers.vue'),
  },
  {
    path: '/apps/seminar/setPrice/:id',
    name: 'apps-seminar-setPrice',
    component: () => import('@/views/apps/seminar/SeminarSetPrice.vue'),
  },
  {
    path: '/apps/seminar/edit/:id',
    name: 'apps-seminar-edit',
    component: () => import('@/views/apps/seminar/SeminarEdit.vue'),
  },
  {
    path: '/apps/seminar/reserve/:id',
    name: 'apps-seminar-reserve',
    component: () => import('@/views/apps/seminar/SeminarReserve.vue'),
  },
  {
    path: '/apps/seminar/ticket/:id',
    name: 'apps-seminar-ticket',
    component: () => import('@/views/apps/seminar/SeminarTicket.vue'),
    meta: {
      layout: 'full',
    },
  },


  //  Posts
  {
    path: '/apps/post/list',
    name: 'apps-post-list',
    component: () => import('@/views/apps/post/PostList.vue'),
  },
  {
    path: '/apps/post/add',
    name: 'apps-post-add',
    component: () => import('@/views/apps/post/PostAdd.vue'),
  },
  {
    path: '/apps/post/edit/:id',
    name: 'apps-post-edit',
    component: () => import('@/views/apps/post/PostEdit.vue'),
  },
  {
    path: '/apps/post/comments/:id',
    name: 'apps-post-comments',
    component: () => import('@/views/apps/post/PostComments.vue'),
  },
  {
    path: '/apps/post/add/media/:id',
    name: 'apps-post-add-media',
    component: () => import('@/views/apps/post/PostAddMedia.vue'),
  },

  //  Stories
  {
    path: '/apps/stories/add',
    name: 'apps-stories-add',
    component: () => import('@/views/apps/stories/StoriesAdd.vue'),
  },
  {
    path: '/apps/stories/list',
    name: 'apps-stories-list',
    component: () => import('@/views/apps/stories/StoriesList.vue'),
  },
  {
    path: '/apps/story/reports/:id',
    name: 'apps-story-reports',
    component: () => import('@/views/apps/stories/StoryReport.vue'),
  },

  //  Channel
  {
    path: '/apps/channel/list',
    name: 'apps-channel-list',
    component: () => import('@/views/apps/channel/ChannelList.vue'),
  },
  {
    path: '/apps/channel/edit/:id',
    name: 'apps-channel-edit',
    component: () => import('@/views/apps/channel/ChannelEdit.vue'),
  },
  {
    path: '/apps/channel/:id',
    name: 'apps-channel-id',
    resource: 'Support',
    action: 'Support',
    component: () => import('@/views/apps/channel/Channel.vue'),
    meta: {
      // contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  //  Edit Pages
  {
    path: '/apps/home-settings',
    name: 'apps-home-settings',
    component: () => import('@/views/apps/edit-pages/Home.vue'),
  },
  {
    path: '/apps/daily-mantra',
    name: 'apps-daily-mantra',
    component: () => import('@/views/apps/edit-pages/MantraDaily.vue'),
  },
  {
    path: '/apps/home-mantra',
    name: 'apps-home-mantra',
    component: () => import('@/views/apps/edit-pages/MantraHome.vue'),
  },
  {
    path: '/apps/contactUs',
    name: 'apps-contactUs',
    component: () => import('@/views/apps/edit-pages/contactUs.vue'),
  },
  {
    path: '/apps/aboutUs',
    name: 'apps-aboutUs',
    component: () => import('@/views/apps/edit-pages/aboutUs.vue'),
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/admins',
    name: 'apps-admins',
    component: () => import('@/views/apps/admins/admins.vue'),
  },
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },
  {
    path: '/apps/block',
    name: 'apps-block',
    component: () => import('@/views/apps/block/Block.vue'),
  },
  {
    path: '/apps/block/user/:id',
    name: 'apps-block-user',
    component: () => import('@/views/apps/block/UserBlock.vue'),
  },

  //  Financial

  {
    path: '/apps/financial/wallets/list',
    name: 'apps-financial-wallets-list',
    component: () => import('@/views/apps/financial/wallets/WalletsList.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/financial/wallets/info/:id',
    name: 'apps-financial-wallets-info',
    component: () => import('@/views/apps/financial/wallets/WalletInfo.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/financial/payments/list',
    name: 'apps-financial-payments-list',
    component: () => import('@/views/apps/financial/payments/PaymentsList.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/financial/payments/info/:id',
    name: 'apps-financial-payments-info',
    component: () => import('@/views/apps/financial/payments/PaymentInfo.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/orders/list',
    name: 'apps-orders-list',
    component: () => import('@/views/apps/orders/OrdersList.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },
  {
    path: '/apps/orders/info/:id',
    name: 'apps-orders-info',
    component: () => import('@/views/apps/orders/OrderInfo.vue'),
    meta: {
      resource: 'Accountent',
      action: 'Accountent',
    }
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },

  // Fortune
  {
    path: '/apps/fortune',
    name: 'apps-fortune',
    component: () => import('@/views/apps/fortune/Fortune.vue'),
  },
  {
    path: '/apps/mantra/list',
    name: 'apps-mantra-list',
    component: () => import('@/views/apps/fortune/MantraList.vue'),
  },
  {
    path: '/apps/mantra/add',
    name: 'apps-mantra-add',
    component: () => import('@/views/apps/fortune/MantraAdd.vue'),
  },
  {
    path: '/apps/mantra/edit/:id',
    name: 'apps-mantra-edit',
    component: () => import('@/views/apps/fortune/MantraEdit.vue'),
  },

  // Consultant
  {
    path: '/apps/Consultant/Requests',
    name: 'apps-consultant-numbers-list',
    component: () => import('@/views/apps/Consultant/NumbersList.vue'),
  },

  // Present Seminar
  {
    path: '/apps/PresentSeminar/Requests',
    name: 'apps-present-seminar-list',
    component: () => import('@/views/apps/PresentSeminar/List.vue'),
  },

  // Exam
  {
    path: '/apps/exam/list',
    name: 'apps-exam-list',
    component: () => import('@/views/apps/exam/ExamList.vue'),
  },
  {
    path: '/apps/exam/create',
    name: 'apps-exam-create',
    component: () => import('@/views/apps/exam/ExamCreate.vue'),
  },
  {
    path: '/apps/exam/edit/:id',
    name: 'apps-exam-edit',
    component: () => import('@/views/apps/exam/ExamEdit.vue'),
  },
  {
    path: '/apps/exam/question/list',
    name: 'apps-exam-question-list',
    component: () => import('@/views/apps/exam/question/QuestionList.vue'),
  },
  {
    path: '/apps/exam/question/create',
    name: 'apps-exam-question-create',
    component: () => import('@/views/apps/exam/question/QuestionCreate.vue'),
  },
  {
    path: '/apps/exam/question/edit/:id',
    name: 'apps-exam-question-edit',
    component: () => import('@/views/apps/exam/question/QuestionUpdate.vue'),
  },
  {
    path: '/apps/exam/members/list/:id',
    name: 'apps-exam-members-list',
    component: () => import('@/views/apps/exam/ExamMembersList.vue'),
  },
  {
    path: '/apps/exam/member/exams/list/:userId/:examId',
    name: 'apps-exam-member-exams-list',
    component: () => import('@/views/apps/exam/MemberExamsList.vue'),
  },
  {
    path: '/apps/exam/exams/member/:id',
    name: 'apps-exam-exams-member-detail',
    component: () => import('@/views/apps/exam/MemberExamDetail.vue'),
  },

   // Transgressive
  {
    path: '/apps/Transgressive/list',
    name: 'apps-Transgressive-list',
    component: () => import('@/views/apps/Transgressive/TransgressesList.vue'),
  },
  {
    path: '/apps/Transgressive/users',
    name: 'apps-Transgressive-users',
    component: () => import('@/views/apps/Transgressive/TransgressiveUsers.vue'),
  },
]
