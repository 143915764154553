/* eslint-disable */
export default {
  namespaced: true,

  state: {
    isConnected: false,
  },

  mutations: {
    setConnectionState(state, payload) {
      state.isConnected = payload;
    },
  },

  actions: {
    setConnectionState(context, payload) {
      context.commit("setConnectionState", payload);
    },
  },

  getters: {
    getConnectionState(state) {
      return state.isConnected;
    },
  },
};
