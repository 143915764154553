export default {
    state: () => {
        return {
            SocketUserId: 2,
            onlineUsers:0,
        };
    },
    getters: {
        SocketId(state) {
            return state.SocketUserId;
        },
        OnlineUsers(state) {
            return state.onlineUsers;
        },
    },
    mutations: {
        SetOnlineUsers(state,count){
            state.onlineUsers = count;
        },
        SetDefaultSocketId(state) {
            state.SocketUserId = 0;
        },
        sendMessage(state, resultId) {
            state.SocketUserId = resultId;
        },
    },
    actions: {

    },
};